<template>
    <div class="distributionMember">
        <div class="search">
            <a-input class="search-item" v-model="ParentUserName" placeholder="推荐人"></a-input>
            <a-input class="search-item" v-model="nickName" placeholder="昵称/姓名"></a-input>
            <a-input class="search-item" v-model="phoneNumber" placeholder="手机号"></a-input>
            <a-select class="search-item" v-model="levelId" v-if="levelList.length>0">
                <a-select-option value="" >
                    全部
                </a-select-option>
                <a-select-option v-for="item in levelList" :value="item.Id">
                    {{item.Name}}
                </a-select-option>

            </a-select>
            <a-button class="search-item" @click="_reset()">重 置</a-button>
            <a-button class="search-item" type="primary" @click="_search()">查 询</a-button>
            
        </div>
        <div class="info" v-if="situationTotal">
            <div class="info-content">
                <div class="content-icon">
                    <a-icon type="bar-chart" class="logo"/>
                </div>
                <div class="info-main">
                    <div class="title">全部分销员</div>
                    <div class="content">{{situationTotal.Count}}</div>
                </div>
                <div class="info-main">
                    <div class="title">可提现佣金</div>
                    <div class="content">{{situationTotal.CashOut}}</div>
                </div>
                <div class="info-main">
                    <div class="title">已提现佣金</div>
                    <div class="content">{{situationTotal.AlreadyAmount}}</div>
                </div>
                <div class="info-main">
                    <div class="title">今日新增</div>
                    <div class="content">{{situationTotal.TodayCount}}</div>
                    <div class="title">昨日新增：{{situationTotal.AgoDayCount}}</div>
                </div>
            </div>
        </div>
        <div :style="'width:'+pageWidth+'px'" v-if="tableList.length>0">
            <a-table :pagination="false" :columns="tableColumns" :data-source="tableList" :scroll="{ x: 1300 }">
                <div slot="nickName" slot-scope="text, record">
                    <a-avatar :src="record.avatarUrl"/>
                    <div>{{record.nickName}}</div>
                </div>
                <div slot="phoneNumber" slot-scope="text, record">
                    <div>{{record.businessCardName}}</div>
                    <div>{{record.phoneNumber}}</div>
                </div>
                <div slot="LevelName" slot-scope="text, record">
                    <div>{{record.LevelName}}</div>
                    <div>{{record.count}}</div>
                </div>
                <div slot="CashOut" slot-scope="text, record">
                    <div class="CumulativeIncome">可提现: {{record.CumulativeIncome}}元</div>
                    <div class="CashOut">已提现:{{record.CashOut}}元</div>
                </div>
                <div slot="ParentNickName" slot-scope="text, record">
                    <a-avatar :src="record.ParentAvatarUrl"/>
                    <div>{{record.ParentNickName}}</div>
                </div>
                <div slot="Id" slot-scope="text, record">
                    <span class="table-action" @click="_info(record.Id,1)">详情</span>
                    <span class="table-action" @click="_info(record.Id,2)">订单</span>
                    <span class="table-action" @click="_info(record.Id,3)">提现</span>
                    <!--<a-popconfirm title="您确定要删除吗?" @confirm="_del(record.Id)" okText="确定" cancelText="取消">-->
                        <!--<span class="table-action">删除</span>-->
                    <!--</a-popconfirm>-->

                </div>
            </a-table>
            <a-pagination v-if="total" style="margin-top: 35px;text-align: right;" v-model="Page" @change="ChangePage" :pageSize="Limit" :total="total" show-less-items/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "distributionMember",
        data() {
            return {
                userinfo: JSON.parse(sessionStorage.getItem("userInfo")),
                tableList: [],
                pageWidth: '',
                tableColumns: [
                    {
                        title: '分销员',
                        dataIndex: 'nickName',
                        key: 'nickName',
                        scopedSlots: {customRender: 'nickName'},
                        width: 240,
                    },
                    {
                        title: '姓名/手机',
                        dataIndex: 'phoneNumber',
                        align: 'center',
                        key: 'phoneNumber',
                        scopedSlots: {customRender: 'phoneNumber'},
                        width: 240,
                    },
                    {
                        title: '分销等级/下级人数',
                        dataIndex: 'LevelName',
                        align: 'center',
                        key: 'LevelName',
                        scopedSlots: {customRender: 'LevelName'},
                        width: 240,
                    },
                    {
                        title: '可提现佣金/已提现佣金',
                        dataIndex: 'CashOut',
                        align: 'center',
                        key: 'CashOut',
                        scopedSlots: {customRender: 'CashOut'},
                        width: 240,
                    },
                    {
                        title: '推荐人',
                        dataIndex: 'ParentNickName',
                        align: 'center',
                        key: 'ParentNickName',
                        scopedSlots: {customRender: 'ParentNickName'},
                        width: 240,
                    },
                    {
                        title: '操作',
                        dataIndex: 'Id',
                        align: 'Id',
                        key: 'Id',
                        scopedSlots: {customRender: 'Id'},

                    },

                ],
                total: '',
                Page: 0,
                Limit: 8,
                ParentUserName: '',
                nickName: '',
                Level: '',
                Name: '',
                phoneNumber: '',
                situationTotal:'',
                levelList:[],
                levelId:'',

            }
        },
        created() {
            this.pageWidth = document.documentElement.clientWidth - 440;
            this.loadData();
            this.situation();
            this._levelList();
            
        },
        methods: {
            _levelList(){
                let self = this;
                this.$axios.get(1245,{},res=>{
                    if(res.data.code==0){
                        self.levelList = res.data.data;

                    }
                })
            },
            situation(){
                let self = this;
                this.$axios.get(1239,{},res=>{
                    console.log(res);
                    if(res.data.code==0){
                        self.situationTotal = res.data.data;
                    }else{
                        self.situationTotal = '';
                        this.$message.error(res.data.msg);
                    }
                })
            },
            loadData() {
                this.$message.loading('加载中...', 0);
                let self = this;
                this.$axios.get(1236, {
                    pagesize: self.Limit,
                    p: self.Page,
                    ParentUserName: self.ParentUserName,
                    nickName: self.nickName,
                    Level: self.Level,
                    Name: self.Name,
                    phoneNumber: self.phoneNumber,
                    LevelId:self.levelId,

                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        self.tableList = res.data.data;
                        self.total = res.data.total;
                       
                    } else {
                        self.tableList =[];
                        this.$message.error(res.data.msg)
                    }
                })
            },
            _reset() {
                this.ParentUserName = '';
                this.nickName = '';
                this.Level = '';
                this.Name = '';
                this.phoneNumber = '';
                this.Page = 0;
                this.levelId = '';
                this.loadData();
            },
            _search() {
                this.Page = 0;
                this.loadData();
            },
            ChangePage(value) {
                this.Page = value;
                this.loadData();
            },
            _del(id) {
                
            },

            _info(id,key){
                this.$router.push({path: '/distributionInfo',query:{id:id,tabKey:key}})
            }
        }
    }
</script>

<style scoped lang="less">
    .distributionMember {
        width: 100%;
        /*height: 100%;*/
        background: #FFFFFF;
        padding: 20px;
    }

    .search {
        display: flex;

        .search-item {
            width: 156px;
            margin-right: 10px;
        }
    }

    .info {

        margin-top: 20px;
        margin-bottom: 15px;
        width: 100%;
        background: #FCFCFC;
        border: 1px solid #E4E5E6;
        border-radius: 4px;
        .info-content {
            display: flex;
            align-items: center;
            width: 50%;
            height: 100px;
            .content-icon {
                box-sizing: border-box;
                width: 50px;
                height: 50px;
                line-height: 60px;
                margin: 0 30px;
                border-radius: 50%;
                text-align: center;
                background: #2994FF;
                i {
                    font-size: 30px;
                    color: #fff;
                }
            }
            .info-main {
                display: flex;
                flex-direction: column;
                flex: 1;
                .title {
                    white-space: nowrap;
                    font-size: 14px;
                    color: #999999;
                }
                .content {
                    color: #2994ff;
                    font-size: 14px;
                }
            }
        }

    }

    .CumulativeIncome {
        margin: auto;
        background: #75C255;
        border-radius: 2px;
        color: #ffffff;
        font-size: 12px;
        padding: 0px 5px;
        width: 60%;
        margin-bottom: 5px;

        border-radius: 2px;
    }

    .CashOut {
        background: #FB6638;
        color: #ffffff;
        font-size: 12px;
        padding: 0px 5px;
        width: 70%;
         margin: auto;
        border-radius: 2px;
    }

    .table-action {
        color: #2994FF;
        font-size: 13px;
        margin-right: 31px;
    }
</style>